<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../assets/build/vfs_fonts";
import apiConfig from "../../../config/api";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import numbro from "numbro";

export default {
  props: {
    dataExport: Object,
    type: Number,
  },
  data: () => ({
    newProduct: [],
    user: "",
    numberToTh: "",
  }),
  async created() {
    console.log(this.type);
    console.log(this.dataExport);
    await this.getUser();
    await this.exports();
  },

  methods: {
    async getUser() {
      const userId = Decode.decode(localStorage.getItem("boss"));

      let responseData = [];

      try {
        responseData = await userApi.user.getOne(userId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.user = responseData.data;
      }
    },

    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE128", displayValue: false });

      return canvas.toDataURL("image/png");
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      //setProduct Arr
      this.setProduct();

      // convert num to text TH
      this.convertNumberToTh();

      const html = [
        this.dataExport.is_cancel === "1"
          ? {
              table: {
                widths: ["70%", "30%"],
                body: [
                  [
                    {
                      text: this.type === 1 ? "ใบรับสินค้า" : "ใบสั่งซื้อ",
                      fontSize: 14,
                      fontSize: 14,
                      border: [false, false, false, false],
                      margin: [-5, -3, 0, 0],
                    },
                    {
                      rowSpan: 2,
                      text: `- ยกเลิกแล้ว -`,
                      fontSize: 16,
                      border: [false, false, false, false],
                      alignment: "right",
                      margin: [0, 0, -2, 0],
                      bold: true,
                      color: "#B22222",
                    },
                  ],
                  [
                    {
                      text:
                        this.type === 1 ? "Receive Order" : "Purchase Order",
                      fontSize: 14,
                      border: [false, false, false, false],
                      margin: [-5, -5, 0, 0],
                    },
                  ],
                ],
              },
              margin: [0, 0, 0, 7],
            }
          : this.dataExport.status === "2"
          ? {
              table: {
                widths: ["70%", "30%"],
                body: [
                  [
                    {
                      text: this.type === 1 ? "ใบรับสินค้า" : "ใบสั่งซื้อ",
                      fontSize: 14,
                      fontSize: 14,
                      border: [false, false, false, false],
                      margin: [-5, -3, 0, 0],
                    },
                    {
                      rowSpan: 2,
                      text: `- ไม่อนุมัติ -`,
                      fontSize: 16,
                      border: [false, false, false, false],
                      alignment: "right",
                      margin: [0, 0, -2, 0],
                      bold: true,
                      color: "#FF0000",
                    },
                  ],
                  [
                    {
                      text:
                        this.type === 1 ? "Receive Order" : "Purchase Order",
                      fontSize: 14,
                      border: [false, false, false, false],
                      margin: [-5, -5, 0, 0],
                    },
                  ],
                ],
              },
              margin: [0, 0, 0, 7],
            }
          : [
              {
                text: this.type === 1 ? "ใบรับสินค้า" : "ใบสั่งซื้อ",
                fontSize: 14,
              },
              {
                text: this.type === 1 ? "Receive Order" : "Purchase Order",
                margin: [0, 0, 0, 10],
                fontSize: 14,
              },
            ],
        ,
        {
          columns: [
            {
              width: "50%",
              text: `ผู้รับเงิน/Payee. ${this.dataExport.vendor.vendor_name}`,
              fontSize: 10,
            },
            {
              width: "50%",
              text: `วันที่/Issue date. ${moment(
                this.dataExport.createdAt
              ).format("DD/MM/YYYY - HH:mm:ss น.")}`,
              alignment: "right",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },

        {
          columns: [
            {
              width: "70%",
              text: `สาขา ${
                this.dataExport.vendor.vendor_branch === "สาขา"
                  ? this.dataExport.vendor.vendor_sub_branch_name
                  : this.dataExport.vendor.vendor_branch
              }    เลขประจำตัวผู้เสียภาษี/TAX ID. ${
                this.dataExport.vendor.vendor_id_card
              }`,
              fontSize: 10,
            },
            {
              width: "30%",
              text: `เลขที่/No. ${
                this.type === 1
                  ? this.dataExport.receive_no
                  : this.dataExport.purchase_no
              }`,
              alignment: "right",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 2],
        },

        {
          table: {
            widths: ["70%", "30%"],
            body: [
              [
                {
                  text: `ที่อยู่/Address. ${this.dataExport.vendor.vendor_address}`,
                  fontSize: 10,
                  border: [false, false, false, false],
                  margin: [-5, 0, 0, 0],
                },
                {
                  rowSpan: 2,
                  image: this.textToBase64Barcode(
                    `${
                      this.type === 1
                        ? this.dataExport.receive_no
                        : this.dataExport.purchase_no
                    }`
                  ),
                  // qr: `${apiConfig.ENDPOINT_CLIENT}#/purchase/PU2-1/edit?id=${this.dataExport.id}`,
                  width: 130,
                  height: 35,
                  alignment: "right",
                  border: [false, false, false, false],
                  margin: [0, 0, -5, 0],
                },
              ],
              [
                {
                  text: `เบอร์โทร/Tel. ${this.dataExport.vendor.vendor_tel}    Email. ${this.dataExport.vendor.vendor_email}`,
                  fontSize: 10,
                  border: [false, false, false, false],
                  margin: [-5, 0, 0, 0],
                },
                "star-sized",
              ],
            ],
          },
        },

        // LINE
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 595 - 2 * 40,
              y2: 5,
              lineWidth: 1,
            },
          ],
          margin: [0, 0, 0, 5],
        },

        {
          columns: [
            {
              width: "50%",
              text: `ผู้ออก/Issuer. ${this.dataExport.company.company_name}`,
              fontSize: 10,
            },
            {
              width: "50%",
              text: `เลขประจำตัวผู้เสียภาษี/TAX ID. ${
                this.dataExport.companyBranch.tax_id
                  ? this.dataExport.companyBranch.tax_id
                  : this.dataExport.company.tax_id
              }`,
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },

        {
          columns: [
            {
              width: "100%",
              text: `สาขา ${this.dataExport.companyBranch.company_branch_name}`,
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 5],
          columnGap: 10,
        },

        {
          columns: [
            {
              width: "100%",
              text: `ที่อยู่/Address. ${
                this.dataExport.companyBranch.company_branch_address
                  ? this.dataExport.companyBranch.company_branch_address
                  : this.dataExport.company.company_address
              }`,
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },

        {
          columns: [
            {
              width: "100%",
              text: `เบอร์โทร/Tel. ${
                this.dataExport.companyBranch.company_branch_tel
                  ? this.dataExport.companyBranch.company_branch_tel
                  : this.dataExport.company.company_tel
              }    Email. ${
                this.dataExport.companyBranch.company_branch_email
                  ? this.dataExport.companyBranch.company_branch_email
                  : this.dataExport.company.company_email
              }`,
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 5],
          columnGap: 10,
        },

        // TABLE
        {
          table: {
            widths: [50, 135, 40, 60, 60, "*", 70],
            body: this.newProduct,
          },
        },

        // REMARK
        this.dataExport.is_cancel === "1"
          ? {
              columns: [
                {
                  width: "38.2%",
                  columns: [
                    {
                      text: "หมายเหตุ",
                      fontSize: 10,
                      margin: [0, 10, 0, 0],
                    },
                    {
                      text: "ยกเลิกแล้ว",
                      decoration: "underline",
                      alignment: "center",
                      fontSize: 20,
                      margin: [-102, 40, 0, 0],
                      bold: true,
                      color: "#B22222",
                    },
                  ],
                },
                {
                  table: {
                    widths: ["60.7%", "*"],
                    body: [
                      [
                        {
                          text: "มูลค่ารวมก่อนภาษี(บาท)/Pre Vat Amount",
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                        {
                          text: numbro(
                            this.dataExport.total_price_before_vat
                          ).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: "ส่วนลด(บาท)/Discount",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(this.dataExport.discount).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "ภาษี(บาท)/VAT",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(
                            this.dataExport.total_price_include_vat
                          ).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "มูลค่ารวมทั้งสิ้น(บาท)/Grand Total",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(this.dataExport.final_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                    ],
                  },
                },
              ],
              columnGap: 10,
              margin: [0, 0, 0, 5],
            }
          : this.dataExport.status === "2"
          ? {
              columns: [
                {
                  width: "39.4%",
                  columns: [
                    {
                      text: "หมายเหตุ",
                      fontSize: 10,
                      margin: [0, 10, 0, 0],
                    },
                    {
                      text: "ไม่อนุมัติ",
                      decoration: "underline",
                      alignment: "center",
                      fontSize: 20,
                      margin: [-102, 40, 0, 0],
                      bold: true,
                      color: "#FF0000",
                    },
                  ],
                },
                {
                  table: {
                    widths: ["60.7%", "*"],
                    body: [
                      [
                        {
                          text: "มูลค่ารวมก่อนภาษี(บาท)/Pre Vat Amount",
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                        {
                          text: numbro(
                            this.dataExport.total_price_before_vat
                          ).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: "ส่วนลด(บาท)/Discount",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(this.dataExport.discount).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "ภาษี(บาท)/VAT",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(
                            this.dataExport.total_price_include_vat
                          ).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "มูลค่ารวมทั้งสิ้น(บาท)/Grand Total",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(this.dataExport.final_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                    ],
                  },
                },
              ],
              columnGap: 10,
              margin: [0, 0, 0, 5],
            }
          : {
              columns: [
                {
                  width: "38.2%",
                  columns: [
                    {
                      text: "หมายเหตุ",
                      fontSize: 10,
                      margin: [0, 10, 0, 0],
                    },
                    {
                      text: this.dataExport.note,
                      decoration: "underline",
                      fontSize: 10,
                      margin: [-102, 40, 0, 0],
                    },
                  ],
                },
                {
                  table: {
                    widths: ["60.7%", "*"],
                    body: [
                      [
                        {
                          text: "มูลค่ารวมก่อนภาษี(บาท)/Pre Vat Amount",
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                        {
                          text: numbro(
                            this.dataExport.total_price_before_vat
                          ).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: "ส่วนลด(บาท)/Discount",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(this.dataExport.discount).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "ภาษี(บาท)/VAT",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(
                            this.dataExport.total_price_include_vat
                          ).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                      [
                        {
                          text: "มูลค่ารวมทั้งสิ้น(บาท)/Grand Total",
                          fontSize: 9,
                          alignment: "right",
                        },
                        {
                          text: numbro(this.dataExport.final_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          fontSize: 9,
                          alignment: "right",
                        },
                      ],
                    ],
                  },
                },
              ],
              columnGap: 10,
              margin: [0, 0, 0, 5],
            },

        // THAI TEXT
        {
          columns: [
            {
              table: {
                widths: ["50%", "50%"],
                body: [
                  [
                    {
                      text: "จำนวนเงินรวมทั้งสิ้น",
                      fontSize: 14,
                      alignment: "right",
                      border: [false, false, false, false],
                      bold: true,
                    },
                    {
                      text: this.numberToTh,
                      fontSize: 14,
                      alignment: "right",
                      border: [false, false, false, false],
                      margin: [0, 0, -5, 0],
                      bold: true,
                    },
                  ],
                ],
              },
            },
          ],
          columnGap: 10,
          margin: [0, 10, 0, 5],
        },

        // APPROVE
        {
          columns: [
            {
              width: "50%",
              text: "อนุมัติโดย/Approve by",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "ผู้ขายยอมรับ/Accepted by",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 10],
        },

        // SIGNATURE
        {
          columns: [
            {
              width: "50%",
              text: "..............................................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "..............................................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              text: "(..............................................................................................)",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "(..............................................................................................)",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              text: "วันที่/Date........................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "วันที่/Date........................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
      ];

      const pdfData = {
        content: html,
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setProduct() {
      this.dataExport.purchaseOrderItems.forEach((element, index) => {
        if (index === 0) {
          this.newProduct.push([
            {
              rowSpan: 2,
              fontSize: 9,
              text: "รหัส\nID",
            },

            {
              rowSpan: 2,
              fontSize: 9,
              text: "ชื่อสินค้า\nProduct Name",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "จำนวน\nQuantity",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "ราคา/หน่วย\nPrice/Unit",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "ส่วนลด/หน่วย\nDiscount/Unit",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "ภาษี (%)\nVAT (%)",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "ยอดรวม\nTotal",
              alignment: "right",
            },
          ]);
          this.newProduct.push(["rowspan"]);
        }
        this.newProduct.push([
          {
            text: element.product.product_code,
            fontSize: 9,
            alignment: "right",
          },
          {
            text:
              element.product.product_name.length > 28
                ? element.product.product_name.substring(0, 28) + "..."
                : element.product.product_name,
            fontSize: 9,
          },
          {
            text: this.type === 1 ? element.item_amt_recieve : element.item_amt,
            fontSize: 9,
            alignment: "right",
          },
          {
            text: numbro(element.product.product_cost_price).format({
              thousandSeparated: true,
              mantissa: 2,
            }),
            fontSize: 9,
            alignment: "right",
          },
          {
            text: numbro(
              element.item_discount ? element.item_discount : 0
            ).format({
              thousandSeparated: true,
              mantissa: 2,
            }),
            fontSize: 9,
            alignment: "right",
          },
          {
            text: this.dataExport.tax_type === "0" ? "0%" : "7%",
            // text: numbro(
            //   this.type === 1
            //     ? (element.item_amt_recieve *
            //         element.product.product_cost_price *
            //         7) /
            //         100
            //     : element.item_vat
            //     ? element.item_vat
            //     : 0
            // ).format({
            //   thousandSeparated: true,
            //   mantissa: 2,
            // }),
            fontSize: 9,
            alignment: "right",
          },
          {
            text: numbro(
              this.type === 1
                ? element.item_amt_recieve * element.product.product_cost_price
                : element.item_total_before_vat
                ? element.item_total_before_vat
                : 0
            ).format({
              thousandSeparated: true,
              mantissa: 2,
            }),
            fontSize: 9,
            alignment: "right",
          },
        ]);
      });
    },

    convertNumberToTh() {
      this.numberToTh = ArabicNumberToText(this.dataExport.final_price);
    },
  },
};
</script>
