import axios from "../instance";
import addQuery from "../../utils/addQuery";

export default {
  getAll: async (payload) => {
    const result = await addQuery(payload);

    return axios.api
      .get(`/purchaseOrder?${result}`)
      .then((response) => response.data);
  },
  getAllByStatus: (objQuery) => {
    let newObj = "";

    for (const [key, value] of Object.entries(objQuery)) {
      if (key === "status1") {
        newObj += `status=${value}&`;
      } else {
        newObj += `${key}=${value}&`;
      }
    }
    newObj = newObj.substring(0, newObj.length - 1);

    if (newObj) {
      return axios.api
        .get(`/purchaseOrder?${newObj}`)
        .then((response) => response.data);
    } else {
      return axios.api.get(`/purchaseOrder`).then((response) => response.data);
    }
  },
  getOne: (id) => {
    return axios.api
      .get(`/purchaseOrder/${id}`)
      .then((response) => response.data);
  },

  search: (body, objQuery) => {
    let newObj = "";

    for (const [key, value] of Object.entries(objQuery)) {
      if (key === "status1") {
        newObj += `status=${value}&`;
      } else {
        newObj += `${key}=${value}&`;
      }
    }
    newObj = newObj.substring(0, newObj.length - 1);

    if (newObj) {
      return axios.api
        .post(`/purchaseOrder/search?${newObj}`, body)
        .then((response) => response.data);
    } else {
      return axios.api
        .post(`/purchaseOrder/search`, body)
        .then((response) => response.data);
    }
  },

  create: (body) => {
    return axios.api
      .post("/purchaseOrder", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/purchaseOrder/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/purchaseOrder/${id}`)
      .then((response) => response.data);
  },

  // items
  deleteItems: (body) => {
    return axios.api
      .post(`/purchaseOrder/deleteItems`, body)
      .then((response) => response.data);
  },
  updateItem: (id, body) => {
    return axios.api
      .put(`/purchaseOrder/item/${id}`, body)
      .then((response) => response.data);
  },
};
