import axios from "../instance";
// const companyId = localStorage.getItem("companyId");
import addQuery from "../../utils/addQuery";

export default {
  getAll: async (payload) => {
    const result = await addQuery(payload);
    return axios.api.get(`/vendor?${result}`).then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api.get(`/vendor/${id}`).then((response) => response.data);
  },
  search: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/vendor/search?${result}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api.post("/vendor", body).then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/vendor/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api.delete(`/vendor/${id}`).then((response) => response.data);
  },
};
